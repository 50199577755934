.full-height {
  height: 100vh;
}

.paystack-button {
  cursor: pointer;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  background-color: #bfbfbf;
  font-weight: bold;
  color: red;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  margin-top: 40px;
}

.payment:hover {
  background-color: #69C9ED;
  color: white;
}


.card-body{
  cursor : pointer;
}