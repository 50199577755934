.bg-light-purple {
  background-color: #a770f8;
  height: 100%;
}

.curve-left {
  border-radius: 20px 0px 0px 20px;
}

.side-text {
  font-size: 25px;
}

/*  */

.small {
  display: none;
}

@media only screen and (max-width: 600px) {
  .menu {
    display: block;
  }
  .small {
    display: block;
    /* overflow-x: hidden; */
  }
  .big {
    display: none;
  }
}

@media (min-width: 600px) {
  .makeStyles-toolbar-5 {
    min-height: 0px !important;
  }
  .jss5 {
    min-height: 0px !important;
    display: none !important;
  }
}

