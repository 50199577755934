.landing-side-img {
  background-image: url(../../assets/sudha-bg.png);
  background-size: contain;
  height: 75vh;
  background-repeat: no-repeat;
}

.landing-side-img-sm {
  background-image: url(../../assets/sudha-bg.png);
  background-size: contain;
  height: 50vh;
  background-repeat: no-repeat;
}

/* .landing-main {
  background-color: #fff0f2;
} */

.landing-main {
  background-color: #fff;
}