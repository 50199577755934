#login-bg{
    background-image: url(../../assets/login-bg.png);
    height: 100vh;
    background-size: cover;
}

.fullpage{
    max-height: 100vh;
}

#register-bg{
    background-image: url(../../assets/register-bg.png);
    height: 100vh;
    background-size: cover;
}

.orange{
    color: #F1863B !important;
}

.purple{
    color: #A770F8 !important;
}

.bg-orange{
    background-color: #F1863B !important;
    color: white;
    border: 0;
}