.vertical-line {
  border-left: 2px solid #a770f8;
  height: 100vh;
  /* position: absolute; */
  /* left: 50%; */
}

.bg-cream {
  background-color: #FAFAFA !important;
}

.chart-section {
  max-height: 60%;
  background-color: rgb(238, 233, 233);
}

.cards-section {
  height: 27vh;
}

.my-col {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.wallet-bg {
  background-color: #69c9ed;
}

.transactions-bg {
  background-color: #f07c86;
}
